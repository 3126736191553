<template>
  <v-container pa-0 ma-0 fluid>
    <h3 class="tw-heading-3">{{ $t('global.inputs.email') }}</h3>
    <hr class="kod-hr" />

    <form @submit.prevent="submitEmail" class="mt-8">
      <p>
        {{ $t('profile.inputs.current_email') }}: <strong>{{ authUserEmail }}</strong>
      </p>

      <v-row class="mt-3">
        <v-col cols="12">
          <k-input
            type="email"
            v-model="email"
            :title="$t('profile.inputs.new_email')"
            :invalid="submittedEmail && !isValidField('email')"
            class="tw-w-full"
          />
        </v-col>
      </v-row>

      <v-expand-transition>
        <div v-show="updateEmailStatus.error" class="mb-4 form-error-message">
          <p>{{ $t(updateEmailStatus.error) }}</p>
        </div>
      </v-expand-transition>

      <v-expand-transition>
        <div v-show="wrongEmail" class="mb-4 form-error-message">
          <p>{{ $t('global.form_rules.not_valid_email') }}</p>
        </div>
      </v-expand-transition>

      <v-row class="mt-4">
        <v-col cols="12">
          <k-button
            color="primary"
            :loading="updateEmailStatus.isLoading"
            :success="updateEmailStatus.isSuccess"
            type="submit"
          >
            {{ $t('global.actions.save') }}
          </k-button>
        </v-col>
      </v-row>
    </form>

    <h3 class="mt-15 tw-heading-3">{{ $t('profile.subtitles.newsletter') }}</h3>
    <hr class="mb-8 kod-hr" />

    <k-checkbox v-model="notifications" value="JOBS" :label="$t('profile.inputs.new_jobs')" class="mt-8" />
    <p class="note">{{ $t('profile.descriptions.positions_based_on_technologies') }}</p>

    <k-checkbox v-model="notifications" value="NEWS" :label="$t('profile.inputs.koderia_news')" class="mt-8" />
    <p class="note">{{ $t('profile.descriptions.sending_news') }}</p>

    <k-checkbox v-model="notifications" value="BLOG" label="Koderia Blog" class="mt-8" />
    <p class="note">{{ $t('profile.descriptions.new_blog_articles') }}</p>

    <k-button
      color="primary"
      :loading="updateProfileStatus.isLoading"
      :success="updateProfileStatus.isSuccess"
      class="mt-10"
      @click.native="submitNotifications"
    >
      {{ $t('global.actions.save') }}
    </k-button>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      currentEmail: null,
      email: null,
      wrongEmail: false,
      submittedEmail: false,
      notifications: []
    }
  },
  computed: {
    ...mapGetters({
      authUserEmail: 'AUTH/getAuthUserEmail',
      profile: 'USER/getUserProfile'
    }),
    updateEmailStatus() {
      return this.$store.getters['AUTH/getStatus']('updateEmailStatus')
    },
    updateProfileStatus() {
      return this.$store.getters['USER/getStatus']('updateProfileStatus')
    }
  },
  watch: {
    profile: {
      immediate: true,
      handler(profile) {
        this.notifications = profile.notificationSettings.map(notSetting => notSetting.value)
      }
    }
  },
  methods: {
    isValidField(fieldName) {
      switch (fieldName) {
        case 'email':
          return typeof this.email === 'string' && /(.+)@(.+){2,}\.(.+){2,}/.test(this.email)
      }
    },
    submitEmail() {
      this.submittedEmail = true
      this.wrongEmail = !this.isValidField('email')

      if (this.wrongEmail) {
        return
      }

      this.$store.dispatch('AUTH/updateEmail', this.email)
      this.submittedEmail = false
      this.email = null
    },
    submitNotifications() {
      this.$store.dispatch(
        'USER/updateNotificationSettings',
        { notificationSettings: this.notifications.map(notSetting => ({ value: notSetting })) }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.note {
  color: $koderia-grey;
}

.kod-hr {
  margin-top: 1.063rem !important;
}
</style>
