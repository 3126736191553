<template>
  <v-container pa-0 ma-0 fluid>
    <h3 class="tw-heading-3">{{ $t('profile.subtitles.basic_settings') }}</h3>
    <hr class="kod-hr" />

    <form @submit.prevent="submit" class="mt-2">
      <v-row class="mt-2">
        <v-col>
          <p class="tw-text-sm tw-font-bold tw-pb-2">{{ $t('profile.app_language') }}</p>

          <k-select v-model="language" :items="languages" :placeholder="$t('profile.app_language')" />
        </v-col>
      </v-row>

      <!--            <v-row class="mt-2">-->
      <!--                <v-col cols="12">-->
      <!--                    <p class="tw-text-sm tw-font-bold tw-pb-2">CV</p>-->
      <!--                    <div class="tw-flex tw-items-center tw-gap-x-4 tw-mb-4">-->
      <!--                        <k-button-->
      <!--                            v-if="profile.customCv"-->
      <!--                            :href="profile.customCv"-->
      <!--                            color="secondary"-->
      <!--                            custom-class="tw-py-1 tw-px-4 tw-leading-relaxed"-->
      <!--                        >-->
      <!--                            <k-icon name="download" />-->
      <!--                        </k-button>-->


      <!--                        <div class="tw-flex tw-flex-col tw-gap-4 sm:tw-flex-row">-->
      <!--                            <k-button-->
      <!--                                color="secondary"-->
      <!--                                :loading="isState('customCv', 'PENDING')"-->
      <!--                                :success="isState('customCv', 'SUCCESS')"-->
      <!--                                @click="handleChangeCV()"-->
      <!--                                custom-class="tw-py-1 tw-px-4 tw-leading-relaxed"-->
      <!--                            >-->
      <!--                                {{ profile && profile.customCv ? $t('profile.actions.change_custom_cv') : $t('profile.actions.upload_custom_cv') }}-->
      <!--                            </k-button>-->

      <!--                            <k-button-->
      <!--                                :to="{name: 'profile-cv'}"-->
      <!--                                v-show="profile && !profile.customCv"-->
      <!--                                color="secondary"-->
      <!--                                custom-class="tw-py-1 tw-px-4 tw-leading-relaxed"-->
      <!--                            >-->
      <!--                               {{ $t('profile.actions.create_koderia_cv') }}-->
      <!--                            </k-button>-->

      <!--                            <k-button-->
      <!--                                v-show="profile && profile.customCv"-->
      <!--                                color="text"-->
      <!--                                :disabled="isState('customCv', 'PENDING')"-->
      <!--                                @click="$store.dispatch('USER/UPDATE_PROFILE', { customCv: null })"-->
      <!--                                custom-class="tw-py-1 tw-px-0 hover:tw-text-red hover:tw-bg-white tw-leading-relaxed"-->
      <!--                            >-->
      <!--                                {{ $t('profile.actions.delete_custom_cv') }}-->
      <!--                            </k-button>-->
      <!--                        </div>-->
      <!--                    </div>-->
      <!--                    <p v-if="isState('avatar', 'ERROR')" class="tw-text-red-500 tw-mt-2">-->
      <!--                        {{ $t('global.form_errors.upload_photo_max_size_5mb') }}-->
      <!--                    </p>-->
      <!--                </v-col>-->
      <!--            </v-row>-->

      <!--            <v-expand-transition>-->
      <!--                <div v-show="isState('profile', 'ERROR')" class="mb-4 form-error-message">-->
      <!--                    <p>{{ formStateMessage.profile }}</p>-->
      <!--                </div>-->
      <!--            </v-expand-transition>-->

      <v-row class="mt-4">
        <v-col cols="12">
          <k-button
            :loading="updateProfileStatus.isLoading"
            :success="updateProfileStatus.isSuccess"
            color="primary"
            type="submit"
          >
            {{ $t('global.actions.save') }}
          </k-button>
        </v-col>
      </v-row>
    </form>

    <h3 class="mt-15 tw-heading-3">{{ $t('global.inputs.password') }}</h3>
    <hr class="kod-hr" />

    <form @submit.prevent="submitPassword" class="mt-5">
      <v-row>
        <v-col cols="12">
          <k-password
            v-model="password"
            :title="$t('profile.inputs.new_password')"
            :invalid="submittedPassword && !isValidField('password')"
            class="tw-w-full"
          />
        </v-col>
      </v-row>

      <v-expand-transition>
        <div v-show="updatePasswordStatus.error" class="mb-4 form-error-message">
          <p>{{ $t(updatePasswordStatus.error) }}</p>
        </div>
      </v-expand-transition>

      <v-row class="mt-4">
        <v-col cols="12">
          <k-button
            color="primary"
            :loading="updatePasswordStatus.isLoading"
            :success="updatePasswordStatus.isSuccess"
            type="submit"
          >
            {{ $t('global.actions.save') }}
          </k-button>
        </v-col>
      </v-row>
    </form>

    <div class="mb-6 mt-13">
      <hr class="kod-hr" />
    </div>

    <!--        <v-dialog v-model="dialog" persistent max-width="640">-->
    <!--            <template v-slot:activator="{ on }">-->
    <!--                <a v-on="on" class="delete-account-link">{{ $t('global.actions.delete_account') }}</a>-->
    <!--            </template>-->
    <!--            <v-card class="dialog">-->
    <!--                <h3 class="medium-heading-text">{{ $t('profile.subtitles.delete_account') }}</h3>-->
    <!--                <hr class="kod-hr" />-->
    <!--                <p class="mt-8 body-text">{{ $t('profile.descriptions.account_cancellation_undone') }}</p>-->
    <!--                <div class="mt-10">-->
    <!--                    <k-button color="primary" @click="dialog = false">{{ $t('global.actions.back') }}</k-button>-->
    <!--                    <k-button color="text" custom-class="tw-py-1 tw-px-0 tw-text-red hover:tw-text-red-alt hover:tw-bg-white tw-leading-relaxed ml-4" @click="deleteAccount()">-->
    <!--                        {{ $t('global.actions.delete_account') }}-->
    <!--                    </k-button>-->
    <!--                </div>-->
    <!--            </v-card>-->
    <!--        </v-dialog>-->

    <v-overlay :value="deleteAccountStatus.isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { getRouteLocale } from '@web/constants/language'
import { routerReplaceAfterLanguageChange } from '@web/utils/router'

export default {
  data() {
    return {
      password: null,
      dialog: false,
      submittedProfile: false,
      submittedPassword: false,
      showPassword: false,
      language: null,
      languages: [
        {
          value: 'sk',
          text: 'Slovenský'
        },
        {
          value: 'en',
          text: 'Anglický'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('USER', {
      userAppLanguage: 'getUserAppLanguage'
    }),
    updateProfileStatus() {
      return this.$store.getters['USER/getStatus']('updateProfileStatus')
    },
    updatePasswordStatus() {
      return this.$store.getters['AUTH/getStatus']('updatePasswordStatus')
    },
    deleteAccountStatus() {
      return this.$store.getters['AUTH/getStatus']('deleteAccountStatus')
    }
  },
  watch: {
    userAppLanguage: {
      immediate: true,
      handler(appLanguage) {
        if (appLanguage !== getRouteLocale()) {
          routerReplaceAfterLanguageChange(appLanguage)
        }

        this.$store.dispatch('SET_APP_LANGUAGE', appLanguage)
        this.language = appLanguage
      }
    }
  },
  methods: {
    isValidField(fieldName) {
      switch (fieldName) {
        case 'password':
          return typeof this.password === 'string' && this.password.trim().length > 5
      }
    },
    submit() {
      this.$store.dispatch('USER/updateProfile', {
        appLanguage: {
          value: this.language.toUpperCase()
        }
      })
    },
    submitPassword() {
      this.submittedPassword = true

      if (!this.isValidField('password')) {
        this.$store.commit('USER/SET_FORM_STATE', {
          name: 'password',
          state: 'ERROR',
          message: this.$t('global.form_rules.password_length')
        })
        return
      }

      this.$store.dispatch('AUTH/updatePassword', this.password)
      this.submittedPassword = false
      this.password = null
    },
    deleteAccount() {
      this.dialog = false
      this.$store.dispatch('AUTH/deleteAccount')
    },
    handleChangeCV() {
      console.log(this.$store)
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', '.pdf')
      input.onchange = e => {
        this.$store.dispatch('USER/UPLOAD_PROFILE_CUSTOM_CV', e.target.files[0])
      }
      input.dispatchEvent(new MouseEvent('click'))
    },
    isState(name, state) {
      return this.formState[name] === state
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.mt-15 {
  margin-top: 3.75rem;
}

.mt-13 {
  margin-top: 3.25rem;
}

.kod-hr {
  margin-top: 1.063rem !important;
}

.delete-account-link {
  text-decoration: underline;
  color: $koderia-red;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: $koderia-red-alt;
  }
}

.form-error-message p {
  margin-top: 0 !important;
}

.dialog {
  padding: 4rem;
  border-radius: 0.125rem !important;
}

.avatar-actions {
  display: grid;
  grid-template-columns: 5rem 12.5rem 9.375rem;
  gap: 0.5rem;
  align-items: center;

  .avatar-actions__button--change {
    margin-left: 1rem;
  }

  @media (max-width: 40rem) {
    justify-items: center;

    .avatar-actions__button {
      width: 100%;
    }

    .avatar-actions__button--change {
      margin-top: 1rem;
      margin-left: 0;
    }

    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>
